#bag_popup_container{
    z-index: 9999;
    position: fixed;
}

.hidden {
    display: none;
}

#penni_loader {
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    display: flex;
}

#penni_loader.show_loader {
    opacity: 1;
    pointer-events: all;
}

.pc-loader-spinner {
    /* background: url(../img/loader.svg); */
    width: 100px;
    height: 100px;
    background-size: contain;
}

.pc-loader-spinner svg {
    width: 100%;
}

.box_loader {
    display: flex;
    justify-content: center;
}

.box_loader_spinner svg {
    max-width: 100px;
}

.accordion_disabled, .button_disabled {
    pointer-events: none;
    opacity: 0.2;
}

[pc="accordion-title"] {
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

/*.disabled conainer input submit pointer events none¨*/
.is-disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 18px;
}



#penni_loader .loader-container-inner {
    width: 120px;
    height: 120px;
    position: relative;
}

#penni_loader .loader-line-mask {
    width: 60px;
    height: 120px;
    overflow: hidden;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    animation: rotateLoader 1.2s infinite linear;
}

#penni_loader .loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 5px #7963ff;
}

@keyframes rotateLoader { 0% { transform: rotate(0deg);} 100% { transform: rotate(360deg);}}


span.test_mode_warning {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    opacity: 0.5;
    gap: 5px;
}
.secure_payment_message{
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    opacity: 0.5;
    margin-bottom: 13px;
}

.input_row{
    display: flex;
    gap: 10px;
}

.MuiInputBase-root {
    font-family: inherit !important;
    color: inherit !important;
}



.payment_button_container {
    margin-top: 30px;
}

span.coupon-chip {
    background: #f2f2f2;
    padding: 0px 9px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    color: #000000;
    font-size: 12px;
    align-items: center;
    gap: 5px;
}

[pc=summary-discount-label] {
    display: flex;
    gap: 5px;
}

a.remove-coupon {
    color: inherit;
}

a.remove-coupon svg {
    width: 9px;
}

.autocomplete_item {
    padding: 7px 20px;
    cursor: pointer;
    color: #000000;
}

.autocomplete_item:hover {
    background: #ddd;
}

.cities_list {
    border-radius: 4px;
    box-shadow: 0 0 5px #00000040;
}




.pc-file-uploader-container {
    width: 100%;
    border: dashed 1px #ccc;
    border-radius: 8px;
}

.fileUploaderContainer.pc-file-uploader {
    border: dashed 1px #ccc;
    border-radius: 8px;
    padding: 15px;
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
}

.fileUploaderContainer .upload-content {
    width: 100%;
}


.files-uploader-name {
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f3f3f3;
    padding: 3px 10px;
    border-radius: 20px;
    font-size: 14px;
}

.files-uploader-container {
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.files-uploader-button-label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    font-weight: 400;
}

.files-uploader-button-with-files {
    font-size: 10px;
}

.files-uploader-list-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

label.files-uploader-drop-div{
    margin: 0;
    padding: 0;
}